import {HTTP} from '../../../http-common'
import helper from '../index'

export default {
  getSubZones: async(id) => {
    const res = await HTTP.get('/sub-zones/get', {
      headers: helper.setHeaders(),
      params: {
        id
      }
    })
    return res.data
  },
  getZones: async() => {
    const res = await HTTP.get('/zones-types/get', {
      headers: helper.setHeaders()
    })
    return res.data.data
  },
  getAllZoneGeoJson: async(zone_type_id) => {
    const res = await HTTP.get('/zone-all/get', {
      headers: helper.setHeaders(),
      params: {
        zone_type_id
      }
    })
    return res.data.data
  },
  searchEmployee: async({
    electionId,
    isRegistered,
    isVoted,
    memberId,
    division,
    positionId,
    seatId
  }) => {
    const res = await HTTP.put('/admin/search/employee', {
        electionId,
        isRegistered,
        isVoted,
        memberId,
        division,
        positionId,
        seatId
      },
      {
        headers: helper.setHeaders()
      })
    return res.data.data
  }
}
