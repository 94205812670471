<template>
  <div v-frag>
    <v-container>
      <v-card class='mb-6' elevation='3'>
        <div id='holder' style='position: relative'>
          <div v-if='showSkeletonLoader' ref='overlay' class='overlay standard'>
            <v-skeleton-loader
              height='100%'
              type='image'
              v-bind='attrs'
            ></v-skeleton-loader>
          </div>
          <div id='map' ref='map'></div>
        </div>
      </v-card>
      <v-card class='rounded-lg' elevation='3'>
        <v-card-title>
          <v-col cols='12' md='7'>
            {{ sections.reporting || 'Reporting Map title' }}
          </v-col>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-container fluid>
          <v-card-title>Filters</v-card-title>
          <v-form ref='form'>
            <v-row>
              <v-col class='mb-0' cols='12' md='3'>
                <v-select
                  v-model='personalFilters.election'
                  :items='elections'
                  :loading='loading'
                  label='Elections'
                  outlined
                >
                  <template #selection={item}>
                    {{ item.description }}
                  </template>
                  <template #item={item}>
                    {{ item.description }}
                  </template>
                </v-select>
              </v-col>
              <v-col cols='12' md='3'>
                <v-select
                  ref='register'
                  v-model='personalFilters.register'
                  :items='registerFilter'
                  label='Register'
                  outlined
                ></v-select>
              </v-col>
              <v-col cols='12' md='3'>
                <v-select
                  ref='voted'
                  v-model='personalFilters.voted'
                  :items='votedFilter'
                  label='Voted'
                  outlined
                ></v-select>
              </v-col>
              <v-col cols='12' md='3'>
                <v-select
                  v-model='personalFilters.member'
                  :items='members'
                  :loading='loading'
                  item-value='id'
                  label='Members'
                  outlined
                >
                  <template #selection={item}>
                    {{ item.name }}
                  </template>
                  <template #item={item}>
                    {{ item.name }}
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <div>
              <p class='font-weight-bold text--secondary mb-0'>Division</p>
              <v-radio-group
                v-model='personalFilters.division'
                class='mt-2'
                row
                @change='onFilterDivisionChanged(personalFilters.division)'
              >
                <v-radio label='All' :value='null'></v-radio>
                <v-radio label='Federal' value='Federal'></v-radio>
                <v-radio label='State' value='State'></v-radio>
                <v-radio label='County' value='County'></v-radio>
              </v-radio-group>
            </div>

            <v-row class='mb-n5' md='4' sm='6'>
              <v-col>
                <p class='font-weight-bold'>Position / Seat</p>
                <v-autocomplete
                  v-model='personalFilters.position'
                  :disabled='!personalFilters.division'
                  :items='divisions'
                  dense
                  label='Select the corresponding position'
                  outlined
                  @change='onLocationSelect(personalFilters.position.id)'
                >
                  <template #selection={item}>
                    {{ item.type }}
                  </template>
                  <template #item={item}>
                    {{ item.type }}
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row class='mb-n5' md='4' sm='6'>
              <v-col>
                <p class='font-weight-bold'>Seat Type</p>
                <v-autocomplete
                  v-model='personalFilters.seatType'
                  :disabled='!personalFilters.position'
                  :items='codeValues'
                  dense
                  label='Select the corresponding position'
                  outlined
                >
                  <template #selection={item}>
                    {{ item.seatType }}
                  </template>
                  <template #item={item}>
                    {{ item.seatType }}
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <div class='text-center mt-4'>
              <v-btn
                :disabled='loading'
                :loading='loadingFilterBtn'
                color='primary'
                elevation='3'
                min-width='250'
                x-large
                @click='onFilter'
              >
                Filter
              </v-btn>
            </div>
          </v-form>
        </v-container>

        <v-container v-if='isUsersSelected'>
          <v-card-title>
            <v-col cols='12' md='7'>Selected Users</v-col>
            <v-spacer></v-spacer>
            <v-col cols='12' md='5'>
              <v-text-field
                v-model='search'
                class='rounded-lg'
                dense
                filled
                hide-details
                placeholder='Search'
                prepend-inner-icon='mdi-magnify'
                rounded
              ></v-text-field>
            </v-col>
            <div v-if='selected.length > 0' v-frag>
              <v-col cols='12'>
                <div v-frag>
                  <v-btn
                    active-class='primary-light'
                    class='primary--text text-capitalize'
                    color='primary'
                    outlined
                    style='float: right'
                    @click='communicationReportingMap'
                  >
                    <v-icon color='primary'>mdi-plus</v-icon>
                    <b class='primary--text text-capitalize'>Send Communication</b>
                  </v-btn>
                </div>
              </v-col>
            </div>
          </v-card-title>
          <v-data-table
            v-model='selected'
            :headers='headers'
            :items='employees'
            :items-per-page='-1'
            :loading='loading'
            :search='search'
            :single-select='false'
            class='elevation-1'
            hide-default-footer
            item-key='id'
            show-select
          >
            <template #item.register={item}>
              <span v-if='item.vuid'>Yes</span>
              <span v-else class='red--text'>No</span>
            </template>
            <template #item.voted={item}>
              <span v-if='item.voted'>Yes</span>
              <span v-else class='red--text'>No</span>
            </template>
          </v-data-table>
        </v-container>
      </v-card>
    </v-container>
    <dialog-communication-reporting-map/>
  </div>
</template>

<script>
import electionsService from '../../services/elections/index'
import googleMapsService from '../../services/googleMaps'
import zonesService from '../../services/zones'
import votersGuideService from '../../services/votersGuide/index'
import DialogCommunicationReportingMap from '../communication/components/DialogCommunicationReportingMap'
import { mapActions, mapState } from 'vuex'

export default {
  components: { DialogCommunicationReportingMap },
  data() {
    return {
      showSkeletonLoader: true,
      loadingFilterBtn: false,
      map: null,
      markers: [],
      items: ['A', 'B'],
      employees: [],
      elections: [],
      showFilters: false,
      attrs: {
        boilerplate: false,
        elevation: 2
      },
      search: '',
      selected: [],
      headers: [
        { text: 'Name', align: 'start', value: 'fullName', divider: true },
        { text: 'Company', value: 'companyName', divider: true },
        { text: 'Address', value: 'address', divider: true }
      ],
      loading: false,
      isUsersSelected: false,
      divisions: [],
      codeValues: [],
      personalFilters: {
        election: null,
        register: null,
        voted: null,
        member: null,
        division: null,
        position: null,
        seatType: null
      },
      registerFilter: [
        { text: 'Clear filter', value: 'null' },
        { text: 'Yes', value: true },
        { text: 'No', value: false }
      ],
      votedFilter: [
        { text: 'Clear filter', value: 'null' },
        { text: 'Yes', value: true },
        { text: 'No', value: false }
      ],
      reportingFilters: {}
    }
  },
  computed: {
    ...mapState({
      sections: state => state.cmsStore.sections,
      members: state => state.adminStore.members
    })
  },
  async created() {
    await this.createMapsScript()
    const googleScript = document.getElementById('google-map-script')
    googleScript.addEventListener('load', async () => await this.loadMap())
    await this.initialize()
  },
  methods: {
    ...mapActions({
      getMembers: 'adminStore/getMembers'
    }),
    async createMapsScript() {
      const oldScript = document.getElementById('google-map-script')
      if (oldScript)
        oldScript.remove()
      let script = document.createElement('script')
      script.src = await googleMapsService.fetchGoogleMapsURL()
      script.id = 'google-map-script'
      script.async = true
      let body = document.getElementById('body')
      body.appendChild(script)
    },
    async initialize() {
      this.loading = true
      await Promise.allSettled([
        this.getElections(),
        this.getMembers(),
        this.getDataAfterRoute()
      ])
      this.loading = false
    },
    async getDataAfterRoute() {
      if (this.$store.getters.getPushRoute) {
        this.reportingFilters = this.$store.getters.reportingFilters
        const { employees } = await zonesService.searchEmployee({
          electionId: this.reportingFilters.election?.id,
          isRegistered: this.reportingFilters.register,
          isVoted: this.reportingFilters.voted,
          memberId: this.reportingFilters.member,
          division: this.reportingFilters.division,
          positionId: this.reportingFilters.position?.id,
          seatId: this.reportingFilters.seatType?.id
        })
        this.employees = employees
        this.isUsersSelected = true
      }
      this.$store.commit('changeRoutePush', false)
    },
    async getElections() {
      this.elections = await electionsService.getElections()
    },
    async loadMap() {
      this.map = new window.google.maps.Map(this.$refs.map, {
        center: { lat: 31, lng: -100 },
        zoom: 5.9,
        disableDefaultUI: true
      })
      await this.loadGeoJson(15)
    },
    async loadGeoJson(id = null, allZone) {
      let res = {}
      try {
        res = allZone ? await zonesService.getAllZoneGeoJson(id) : await zonesService.searchEmployee(id)
      } catch (e) {
        this.loading = false
        this.showSkeletonLoader = false
        console.log(e)
      }
      this.showSkeletonLoader = false
      const tempObject = {
        type: 'FeatureCollection',
        features: allZone ? res.geometry.position : [res.geometry.position]
      }
      this.map.data.addGeoJson(tempObject)
      this.map.data.setStyle(feature => {
        const zoneTypeId = feature.getProperty('zoneTypeId')
        const color = zoneTypeId === res.geometry.zoneTypeId ? res.geometry.color : feature.getProperty('color')
        return {
          fillColor: color,
          fillOpacity: 0.05,
          strokeColor: color,
          strokeWeight: 1
        }
      })
      this.map.data.addListener('click', (event) => {
        const infoWindow = new window.google.maps.InfoWindow({
          content:
            '<h3>Zone:</h3>' + `<p>${ event.feature.getProperty('fID') }</p>`
        })
        infoWindow.setPosition(event.latLng)
        infoWindow.open({
          map: this.map
        })
      })
      if (res.employees) {
        this.employees = res.employees
        this.showFilters = true
        this.displayEmployees(res.employees)
      }
      this.showSkeletonLoader = false
    },
    async onFilter() {
      this.loadingFilterBtn = true
      try {
        const { employees } = await zonesService.searchEmployee({
          electionId: this.personalFilters.election?.id,
          isRegistered: this.personalFilters.register,
          isVoted: this.personalFilters.voted,
          memberId: this.personalFilters.member,
          division: this.personalFilters.division,
          positionId: this.personalFilters.position?.id,
          seatId: this.personalFilters.seatType?.id
        })
        this.employees = employees
        this.displayEmployees(this.employees)
      } catch (e) {
        this.loadingFilterBtn = false
        console.log(e)
      }
      this.isUsersSelected = true
      this.loadingFilterBtn = false
    },
    async onFilterDivisionChanged(division) {
      this.divisions = []
      try {
        this.divisions = await votersGuideService.getDivisionType(division)
      } catch (err) {
        console.log(err)
      }
    },
    async onLocationSelect(item) {
      this.codeValues = []
      try {
        this.codeValues = await votersGuideService.getCodeValue(item)
      } catch (err) {
        console.log(err)
      }
    },
    communicationReportingMap() {
      let aux = []
      for (let index in this.selected) {
        aux.push(this.selected[index].id)
      }
      this.$store.dispatch('crud', {
        dialog: true,
        title: 'New Communication',
        id: null,
        data: {
          type_send: 2,
          employees: aux
        }
      })
    },
    displayEmployees(employees) {
      this.clearMarkers()
      this.markers = employees.map(employee => {
        if (employee.latitude && employee.longitude) {
          const latLng = new window.google.maps.LatLng(
            employee.latitude,
            employee.longitude
          )
          const marker = new window.google.maps.Marker({
            position: latLng,
            map: this.map
          })
          const infoWindow = new window.google.maps.InfoWindow({
            content:
              '<h3>Name:</h3>' +
              `<p>${ employee.fullName }</p>` +
              '<h3>Address:</h3>' +
              `<p>${ employee.address }</p>` +
              '<h3>Email:</h3>' +
              `<p>${ employee.email }</p>`
          })
          marker.addListener('click', () => {
            infoWindow.open({
              anchor: marker,
              map: this.map,
              shouldFocus: false
            })
          })
          return marker
        }
      })
    },
    clearMarkers() {
      if (this.markers.length) {
        this.markers.forEach(marker => {
          if (marker)
            marker.setMap(null)
        })
        this.markers = []
      }
    }
  }
}
</script>

<style lang='scss'>
#map {
  height: 600px;
  background: grey;
}
div#holder {
  position: relative;
}
.hidden {
  display: none;
}
div.overlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(180, 214, 254);
  opacity: 0.9;
  z-index: 1;
}
.v-skeleton-loader__image {
  height: 100% !important;
}
</style>
