<template>
  <div v-frag>
    <v-dialog
      v-model='crud.dialog'
      max-width='1000px'
      persistent
      scrollable
      transition='dialog-bottom-transition'
    >
      <v-card>
        <v-card-title>
          <span class='headline'>{{ crud.title }}</span>
        </v-card-title>
        <v-card-text class='py-2 px-4'>
          <v-container>
            <v-form ref='form' v-model='valid' lazy-validation>
              <v-row>
                <v-col
                  v-if='!crud.data.template'
                  cols='12'
                >
                  <p class='font-weight-bold mb-0'>Template*</p>
                  <v-row>
                    <v-col cols='4' md='3'>
                      <v-checkbox
                        v-model='inputs.templateSelect'
                        :rules='templateSelectRules'
                        :value='1'
                        class='mr-2 d-inline-block'
                        color='primary'
                        label='New communication'
                      ><template v-slot:label ><label class="py-1" @click.stop>New communication</label></template></v-checkbox>
                    </v-col>
                    <v-col cols='4' md='3'>
                      <v-checkbox
                        v-model='inputs.templateSelect'
                        :rules='templateSelectRules'
                        :value='2'
                        class='mr-2 d-inline-block'
                        color='primary'
                        label='Use template'
                        @click='getTemplates'
                      ><template v-slot:label ><label class="py-1" @click.stop>Use template</label></template></v-checkbox>
                    </v-col>

                    <div v-if='inputs.templateSelect == 2' v-frag>
                      <v-col cols='4' md='6'>
                        <v-autocomplete
                          v-model='inputs.template'
                          :items='itemsTemplates'
                          :rules='templateRules'
                          class='mt-3'
                          dense
                          outlined
                          placeholder='Template'
                          required
                          @change='selectTemplate'
                        ></v-autocomplete>
                      </v-col>
                    </div>
                  </v-row>
                </v-col>

                <v-col cols='12'>
                  <p class='font-weight-bold mb-0'>Type*</p>
                  <v-row>
                    <v-col cols='4' md='2'>
                      <v-checkbox
                        v-model='inputs.type'
                        :rules='typeRules'
                        :value='3'
                        class='mr-2 d-inline-block'
                        color='primary'
                        label='All'
                      ><template v-slot:label ><label class="py-1" @click.stop>All</label></template></v-checkbox>
                    </v-col>
                    <v-col cols='4' md='2'>
                      <v-checkbox
                        v-model='inputs.type'
                        :rules='typeRules'
                        :value='1'
                        class='mr-2 d-inline-block'
                        color='primary'
                        label='sms'
                      ><template v-slot:label ><label class="py-1" @click.stop>sms</label></template></v-checkbox>
                    </v-col>
                    <v-col cols='4' md='2'>
                      <v-checkbox
                        v-model='inputs.type'
                        :rules='typeRules'
                        :value='2'
                        class='mr-2 d-inline-block'
                        color='primary'
                        label='Email'
                      ><template v-slot:label ><label class="py-1" @click.stop>Email</label></template></v-checkbox>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  v-if='inputs.type != 1'
                  cols='12'
                >
                  <p class='font-weight-bold mb-0'>Emails*</p>
                  <tags-input
                    v-model='selectedMails'
                    :existing-tags='employees'
                    :only-existing-tags='true'
                    :placeholder="'Type email:'"
                    :typeahead='true'
                    class='mr-4'
                    discard-search-text='Employees: '
                    element-id='tags'
                    id-field='id'
                    text-field='email'
                  ></tags-input>
                </v-col>
                <v-col cols='12'>
                  <p class='font-weight-bold'>Communication Topic*</p>
                  <v-text-field
                    v-model='inputs.topic'
                    :rules='topicRules'
                    dense
                    outlined
                    placeholder='new Campaign'
                    required
                    type='text'
                  ></v-text-field>
                </v-col>

                <div v-if='inputs.type != 1' v-frag>
                  <v-col cols='12'>
                    <v-divider></v-divider>
                  </v-col>

                  <v-col cols='12'>
                    <p class='font-weight-bold'>Email Subject*</p>
                    <v-text-field
                      v-model='inputs.email'
                      :rules='emailRules'
                      dense
                      outlined
                      placeholder='Remaider Email Example'
                      required
                      type='text'
                    ></v-text-field>
                  </v-col>

                  <v-col cols='12'>
                    <p class='font-weight-bold'>Email Message*</p>
                    <wysiwyg
                      v-model='inputs.emailMessage'
                      :rules='emailMessageRules'
                    />
                  </v-col>
                </div>

                <div v-if='inputs.type != 2' v-frag>
                  <v-col cols='12'>
                    <v-divider></v-divider>
                  </v-col>
                  <v-col cols='12'>
                    <p class='font-weight-bold'>Sms Message*</p>
                    <v-textarea
                      v-model='inputs.smsMessage'
                      :rules='smsMessageRules'
                      dense
                      outlined
                      required
                      type='text'
                    ></v-textarea>
                  </v-col>
                </div>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class='d-flex direction-row justify-end'>
          <v-btn
            :disabled='loading'
            class='px-14 mx-4 font-weight-bold primary--text elevation-0'
            color='white'
            large
            @click='closeDialog'
          >
            Cancel
          </v-btn>
          <div v-frag>
            <v-btn
              :disabled='loading'
              :loading='loading'
              class='px-14 mx-4 font-weight-bold white--text'
              color='primary'
              large
              outlined
              @click='dialogSchedule = true'
            >
              Schedule send
              <v-icon right> mdi-send</v-icon>
            </v-btn>

            <div v-frag>
              <v-btn
                :disabled='loading'
                :loading='loading'
                class='px-14 mx-4 font-weight-bold white--text'
                color='primary'
                large
                @click='store'
              >
                Send now
              </v-btn>
            </div>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model='dialogSchedule' persistent width='500'>
      <v-card>
        <v-card-text class='py-2 px-4'>
          <v-container>
            <v-form ref='form' v-model='valid' lazy-validation>
              <v-row>
                <!-- <div v-frag > -->
                <v-col cols='12'>
                  <v-menu
                    ref='menu3'
                    v-model='modalDate'
                    :close-on-content-click='false'
                    max-width='290px'
                    min-width='auto'
                    offset-y
                    transition='scale-transition'
                  >
                    <template #activator='{ on, attrs }'>
                      <p class='font-weight-bold'>Date*</p>
                      <v-text-field
                        :value='computedDateFormattedMomentjs'
                        autocomplete='off'
                        dense
                        outlined
                        required
                        v-bind='attrs'
                        v-on='on'
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model='inputs.date'
                      no-title
                      @input='modalDate = false'
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols='12'>
                  <vue-timepicker
                    v-model='timeView'
                    :minute-interval='15'
                    auto-scroll
                    close-on-complete
                    format='h:mm A'
                    style='width: 100%'
                    @change='updatedTime'
                  ></vue-timepicker>
                  <!-- <v-menu
                    ref="menu4"
                    v-model="modalTime"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <p class="font-weight-bold">Time*</p>
                      <v-text-field
                        v-model="timeView"
                        autocomplete="off"
                        class="mt-3"
                        required
                        outlined
                        dense
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      ref="timepicker"
                      v-model="inputs.time"
                      v-if="modalTime"
                      @change="setTime"
                      @update:period="setTime"
                      @click:hour="setTime"
                      @click:minute="modalTime = false"
                    ></v-time-picker>
                  </v-menu> -->
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class='d-flex direction-row justify-end'>
          <v-btn
            class='px-14 mx-4 font-weight-bold primary--text elevation-0'
            color='white'
            large
            @click='closeDialogSchedule'
          >
            Cancel
          </v-btn>
          <v-btn
            class='px-14 mx-4 font-weight-bold white--text'
            color='primary'
            large
            @click='saveSchedule'
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import communication from '../../../services/communication'
import moment from 'moment'
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'

export default {
  components: {
    VueTimepicker
  },
  data() {
    return {
      inputs: {
        type: 3,
        topic: '',
        email: '',
        emailMessage: '',
        smsMessage: '',
        date: new Date().toISOString().substr(0, 10),
        schedule: false,
        time: '',
        templateSelect: 1,
        template: ''
      },
      loading: false,
      selectedMails: [],
      valid: false,
      topicRules: [(v) => !!v || 'Topic is required'],
      emailRules: [(v) => !!v || 'Email Subject is required'],
      emailMessageRules: [(v) => !!v || 'Email message is required'],
      smsMessageRules: [(v) => !!v || 'Sms message is required'],
      templateRules: [(v) => !!v || 'Template is required'],
      itemsTemplates: [],
      modalTime: false,
      dialogSchedule: false,
      menu3: false,
      modalDate: false,
      menu4: false,
      timeView: ''
    }
  },
  computed: {
    employees() {
      return this.$store.getters.crud.data.allEmployee
    },
    crud() {
      if (this.$store.getters.crud.dialog) {
        this.initialLoad()
      }
      return this.$store.getters.crud
    },
    typeRules() {
      return [this.inputs.type != null || 'At least one item should be selected']
    },
    templateSelectRules() {
      return [this.inputs.templateSelect != null || 'At least one item should be selected']
    },
    computedDateFormattedMomentjs() {
      return this.inputs.date ? moment(this.inputs.date).format('MM/DD/YY') : ''
    }
  },
  methods: {
    initialLoad() {
      const aux = this.inputs
      aux.templateSelect = 1
      aux.template = ''
      aux.type = 3
      aux.sendToSelectMember = 2
      aux.sendTo = 2
      aux.role = []
      aux.schedule = false
      this.timeView = ''
      this.selectedMails = this.$store.getters.crud.data.employees
      this.inputs = aux
      this.inputs.time = ''
      this.inputs.date = new Date().toISOString().substr(0, 10)
      /**
       * Unknown function uncomment if it's need...
       */
      // try {
      //   if (this.$store.getters.crud.id) {
      //     await this.getData();
      // }
      // } catch (e) {
      //   empty
      // }
    },
    async getTemplates() {
      try {
        this.itemsTemplates = await communication.getTemplates()
      } catch (e) {
        console.log(e)
      }
    },
    async selectTemplate() {
      try {
        const res = await communication.show(this.inputs.template)
        let aux = this.inputs
        aux.type = res.type_id
        aux.topic = res.topic
        aux.email = res.subject
        aux.emailMessage = res.text_mail
        aux.smsMessage = res.text_sms
        this.inputs = aux
      } catch (e) {
        console.log(e)
      }
    },
    async saveSchedule() {
      let aux = this.inputs
      aux.schedule = true
      this.inputs = aux
      this.dialogSchedule = false
      await this.store()
    },
    closeDialogSchedule() {
      this.dialogSchedule = false
      let aux = this.inputs
      aux.schedule = false
      aux.date = ''
      aux.time = ''
      this.inputs = aux
    },
    setTime() {
      if (this.inputs.time) {
        const timepicker = this.$refs.timepicker
        this.timeView = timepicker.convert12to24(timepicker.inputHour) + ':' + timepicker.inputMinute + ' ' + timepicker.period.toUpperCase()
      }
    },
    async store() {
      this.loading = true
      let data = []
      this.selectedMails.forEach(item => data.push(item.id))
      if (this.$refs.form.validate()) {
        try {
          let aux = {
            type_id: this.inputs.type,
            type_send: this.crud.data.type_send,

            topic: this.inputs.topic,
            subject: this.inputs.email,
            text_mail: this.inputs.emailMessage,
            text_sms: this.inputs.smsMessage,
            employees: data,

            schedule: this.inputs.schedule,
            date: this.inputs.date,
            time: this.inputs.time,
            template: this.inputs.templateSelect,
            template_id: this.inputs.template,
            reportingMap: true
          }
          if (this.crud.data.overviewEmployee) {
            aux.overviewEmployee = true
          }
          const res = await communication.storeReportingMap(aux)
          this.closeDialog()
          await this.$store.dispatch('alert', {
            show: true,
            color: 'green',
            title: 'Success message!',
            message: res.message,
            response: true
          })
          this.loading = false
        } catch (e) {
          this.loading = false
        }
      } else {
        this.loading = false
      }
    },
    closeDialog() {
      this.$refs.form.reset()
      this.inputs.emailMessage = ''
      this.$store.dispatch('crud', {
        dialog: false,
        title: '',
        id: null,
        data: {}
      })
    },
    updatedTime(event) {
      let aux = this.inputs
      const data = event.data
      if (data.H == '' || data.mm == '' || data.A == '') {
        return
      }
      aux.time = data.kk + ':' + data.mm
      this.inputs = aux
    }
  }
}
</script>
